<template >
  <v-row>
    <v-col class="col-12 col-md-6">
      <v-card>
        <v-card-title>Request a client account</v-card-title>
        <v-card-text class="request-text">
          <p>
            This option will allow the user to request the creation of a new company account, where he will be able to
            manage his users and the creation of reports in his account.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" to="request/account">New Account </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6">
      <v-card>
        <v-card-title>Join an account</v-card-title>
        <v-card-text class="request-text">
          <p>
            If you already have a code obtained through an invitation, you must use this option to enter your code and
            have your profile joined to the account to which you have been invited.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" to="join">Join </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="css" scoped>
.request-text {
}
</style>
